@charset "UTF-8";

/*
#overview
base
*/
/*
#colors
@$color1 #004986
@$color2 #f36f2e
@$textColor #000
*/
$color1: #004986;
$color2: #f36f2e;
$textColor: #000;

$menu_black: #000;
$menu_text_white: #fff;

$tour_text_orange: #EF5A24;
$event_type_text_green: #35B696;

$background-white: #fff;
$background-gray: #F4F4F4;
$background-works: #E9E9E9;
$background-tag: #282828;

$background-gray-sp: #E5E5E5;



// font
$baseFont: 15;
$lineHeight: 1.8;
/*
$mincho: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", serif;
*/
$mincho: "Noto Sans JP","ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
$Gothic: "Noto Sans JP","ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;

// コンテンツ幅
$baseWidth: 1000px;
$baseWidthSp: 91.25%;
$baseWidthPadding: 4.375%;

// キャンバスサイズ
$maxWidth: 2000px;

// ブレークポイント
$bpPcs: 1000px;
$bpTb: 768px;
$bpSp: 640px;
$bpSps: 425px;

$bpPcsOrg: 1100px;

// コンテンツのシフト幅
$contentsShiftWidth: 90px;
$blue-box-width: 1400px;

// z-index
// ↑レイヤー上
// ↓レイヤー下
// eg. z-index: z(child);
$z-index:(
  child,
  nav,
  pulldown,
  header
);
// リストを逆転させる
@function list-reverse($map) {
  $result: ();
  @for $i from length($map)*-1 through -1 {
    $result: append($result, nth($map, abs($i)), comma);
  }
  @return $result;
}
$z-index: list-reverse($z-index);

@function z($name) {
  @return index($z-index, $name);
}

// Weight Setting
$thin: 100;
$eLight: 200;
$light: 300;
$regular: 400;
$medium: 500;
$dBold: 600;
$bold: 700;
$heavy: 800;
$black: 900;