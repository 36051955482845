
.l-header {
  background-color: $menu_black;
  color: $menu_text_white;
  a {
    color: $menu_text_white;
  }
  position: relative;
  z-index: 100;
  height: auto;
  @include bpTb {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
  }
  .header-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 25px;
    @include bpTb {
          padding: 20px 9px 20px 5%;
    }
    .header-main-title {
      margin: 0 10px;
      min-width: 166px;
      @include bpTb {
        min-width: 0;
        width: 60%;
      }
    }

    .menu-block {
      height: 21px;
      @include breakpoint(1490px) {
        margin-left: auto;
        padding-top: 3px;
      }
      @include bpTb {
        display: none;
      }
      .nav {
        padding-top: 0;
        display: flex;
        justify-content: flex-end;

        margin: 0;
        max-width: 100%;
        &-main, &-fix {
          margin: 0 10px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          li {
            position: relative;
            @include breakpoint(1490px) {
              @include f-size(11);
              margin: 0 7px;
              &:not(:first-child):before {
                left: -9px;
              }
            }
          }
        }

        &-main {
          /*width: 780px;*/
          @include breakpoint(1490px) {
            width: auto;
          }
          @include breakpoint($bpPcsOrg) {
            display: none;
          }
        }

        &-fix {
          width: 305px;
          @include breakpoint(1490px) {
            width: auto;
          }
          li {
            width: 135px;
            height: 100%;
            margin: 0 15px 0 40px;
            &:first-child {
              width: 75px;
            }
            @include breakpoint(1280px) {
              &:first-child {
                width: 1px;
              }
              width: 1px;
            }
            @include breakpoint(1490px) {
              margin: 0 5px 0 40px;
            }
            &:not(:first-child):before {
              content: "";
              width: 1px;
              height: 17px;
              border-left: $menu_text_white dotted 1px;
              position: absolute;
              top: 2px;
              left: -40px;
            }
            &:last-child {
              margin: 0 0 0 40px;
            }

            .catalog, .search {
              position: relative;
              @include breakpoint(1280px) {
                p {
                  display: none;
                }
              }
              &:before {
                position: absolute;
                top: 3px;
                left: -25px;
                content: '';
                display: inline-block;
                width: 18px;
                height: 15px;
                background-size: contain;
                @-moz-document url-prefix() {
                  top: 18px;
                }
              }
            }
            .catalog {
              &:before {
                background-image: url("../img/1x/catalog-icon.png");
                @include retina {
                  background-image: url("../img/2x/catalog-icon@2x.png");
                }
                @-moz-document url-prefix() {
                  top: 18px;
                }

              }
            }
            .search {
              &:before {
                background-image: url("../img/SVG/search-icon.svg");
                top: 1px;
                width: 16px;
                height: 16px;
                @-moz-document url-prefix() {
                  top: 18px;
                }
              }
            }
          }
        }

      }
    }
  }

  &.fixed {
    position: fixed;
    top: 0;
    right: 0;
    width: 130px;
    z-index: 1000;
    .header-wrapper {
      padding: 25px 0;
      position: relative;
      .header-main-title {
        display: none;
      }
      .menu-block {
        .nav-main {
          display: none;
        }
        .nav-fix {
          width: 100%;
          li {
            width: 1px;
            height: 100%;
            .catalog:before {
              top: -2px;
              left: -22px;
              @-moz-document url-prefix() {
                top: 12px;
              }
            }
            .search:before {
              top: -1px;
              left: -7px;
              @-moz-document url-prefix() {
                top: 12px;
              }

            }
            &:not(:first-child):before {
              top: -2px;
              left: -31px;
            }
            @media all and (-ms-high-contrast: none) {
              .catalog:before {
                top: -2px;
                left: -47px;
              }
              .search:before {
                top: -1px;
                left: -28px;
              }
              &:not(:first-child):before {
                top: -2px;
                left: -50px;
              }
            }
          }
          li a p {
            display: none;
          }
        }
      }
      .lines-button {
        display: none;
      }
    }

    @include bpTb {
      width: 100%;
      .header-wrapper {
  /*      padding: 9px;*/
        padding: 20px 9px 20px 5%;

        .header-main-title {
          display: block;
        }
        .lines-button {
          display: block;
        }
      }
    }

  }
}
.fixed {
  .megamenu {
    visibility: hidden;
  }
}
.megamenu {
  position: absolute;
  top: 100%;
  left: 0px;
  width: 100vw;
  background-color: rgba(0,0,0,0.9);
  z-index: 100;
  @include bpTb {
    display: none !important;
  }
  &Block {
    display: none;
    position: absolute;
    background-color: rgba(0,0,0,0.9);
    top: 0px;
    left: 0px;
    width: 100%;
    &__inner {
      display: flex;
    }
    &__thumb {

    }
    &__info {
      margin-left: 25px;
      padding-top: 8px;
      position: relative;
    }
    &__title {
      @include f-size(16);
      font-weight: $bold;
      margin-bottom: 15px;
      letter-spacing: 0.1em;
    }
    &__nav {
      display: flex;
      margin-bottom: 70px;
      li {
        @include f-size(12);
        margin-right: 23px;
        &:before {
          content: "▶";
        }
      }
    }
  }
  &ItemBlock {
    border: 1px solid #fff;
    padding: 8px 10px;
    position: relative;
    display: inline-block;
    position: absolute;
    bottom: 25px;
    left: 0px;
    &__title {
      &:before {
        content: "";
        height: 24px;
        width: 38px;
        display: inline-block;
        background: url(../img/SVG/catalog-icon.svg) no-repeat center;
        background-size: auto 70%;
        border-right: 1px dashed #fff;
        margin-right: 8px;
        vertical-align: middle;
      }
    }
    &__btn {
      position: absolute;
      top: 9px;
      right: 9px;
      a {
        display: inline-block;
        background-color: #fff;
        color: $textColor;
        line-height: 30px;
        padding: 0 39px 0 19px;
        position: relative;
        font-size: 10px;
        &:after {
          content: "";
          background: url(../img/SVG/arrow_thin_black.svg) no-repeat center/contain;
          width: 20px;
          height: 4px;
          position: absolute;
          top: 50%;
          margin-top: -4px;
          right: 8px;
        }
      }
    }
    &Rows {
      display: flex;
      align-items: flex-end;
      &__text {
        white-space: nowrap;
        font-size: 10px;
        font-family: $Gothic;
      }
      &__img {
        padding: 0 5px 0 0;
      }
    }
  }
  &LineupBlock {
    border: 1px solid #fff;
    padding: 8px 10px;
    position: relative;
    display: inline-block;
    position: absolute;
    bottom: 25px;
    left: 0px;
    &__inner {
      display: flex;
    }
    &__info {
      margin-left: 15px;
    }
    &__title {
      font-size: 12px;
    }
    &__btn {
      margin-top: 5px;
      a {
        display: inline-block;
        background-color: #fff;
        color: $textColor;
        line-height: 30px;
        padding: 0 39px 0 19px;
        position: relative;
        font-size: 10px;
        &:after {
          content: "";
          background: url(../img/SVG/arrow_thin_black.svg) no-repeat center/contain;
          width: 20px;
          height: 4px;
          position: absolute;
          top: 50%;
          margin-top: -4px;
          right: 8px;
        }
      }
    }
  }
}