// 黒い方
.footer-info-black {
  background-color: $background-works;
  padding: 58px 0 55px;
  margin-top: 114px;
  text-align: center;
  @include bpTb {
    margin-top: 35px;
  }
  .btnBos01 {
    a {
      display: block;
      color: #fff;
      background-color: #000;
      padding: 20px 10px;
      @include f-size(22);
      font-weight: $dBold;
      position: relative;
      @include bpTb {
        @include f-size(20);
      }
      &:after {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        background: url(../img/SVG/arrow_white.svg) no-repeat center/contain;
        position: absolute;
        right: 17px;
        top: 50%;
        margin-top: -8px;
      }
    }
  }
  > .title {
    @include f-size(28);
    display: inline-block;
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
    margin-bottom: 55px;
    @include bpTb {
      @include f-size(20);
      margin-bottom: 22px;
    }
  }
  .info-area {
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    @include bpTb {
      display: block;
      padding: 0 3.7vw;
    }
    .left {
      width: 380px;
      @include bpTb {
        width: 100%;
      }
    }
    .right {
      width: 435px;
      background-color: #fff;
      padding: 15px 20px 20px;
      @include bpTb {
        width: 100%;
        padding: 20px 15px 20px;
        margin-top: 25px;
      }
      .title {
        font-weight: $medium;
        @include f-size(22);
        text-align: left;
        @include bpTb {
          @include f-size(15);
        }
        strong {
          @include f-size(28);
          @include bpTb {
            @include f-size(20);
          }
        }
        &:before {
          content: "";
          display: inline-block;
          background: url(../img/SVG/icon_chat.svg) no-repeat center/contain;
          width: 21px;
          height: 25px;
          margin-right: 12px;
          @include bpTb {
            width: 18px;
            height: 20px;
            margin-right: 6px;
          }
        }
      }
      .text {
        @include f-size(12);
        line-height: 2;
        letter-spacing: 0.1em;
        @include bpTb {
          text-align: left;
          @include f-size(15);
          letter-spacing: 0;
        }
      }
      .thumb {
        display: block;
        margin-top: 10px;
      }
    }
  }
  .breadcrumb {
    margin-bottom: 0;
  }
}

// 白い方
.footer-info {
  padding-top: 58px;
  margin-top: 15px;
  width: 100%;
  background-color: $background-white;
  text-align: center;
  > .title {
    @include f-size(28);
    display: inline-block;
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
    margin-bottom: 55px;
    @include bpTb {
      @include f-size(20);
      margin-bottom: 22px;
    }
  }
  .btnBos01 {
    a {
      display: block;
      color: #fff;
      background-color: #000;
      padding: 20px 10px;
      @include f-size(22);
      font-weight: $dBold;
      position: relative;
      @include bpTb {
        @include f-size(20);
      }
      &:after {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        background: url(../img/SVG/arrow_white.svg) no-repeat center/contain;
        position: absolute;
        right: 17px;
        top: 50%;
        margin-top: -8px;
      }
    }
  }

  .info-area {
    width: 85%;
    margin: 0 auto 55px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include bpTb {
      width: 90%;
      flex-direction: column;
      align-items: center;
    }

    .left, .right {
      width: 45%;

      @include bpTb {
        width: 100%;
      }

    }
    .left {
      @include bpTb {
        text-align: center;
        margin-bottom: 30px;
      }
    }
    .right {
      @include bpTb {
        width: 90%;
        img {
          width: 100%;
        }
      }
      .title {
        font-weight: $medium;
        @include f-size(22);
        text-align: left;
        @include bpTb {
          @include f-size(15);
        }
        strong {
          @include f-size(28);
          @include bpTb {
            @include f-size(20);
          }
        }
        &:before {
          content: "";
          display: inline-block;
          background: url(../img/SVG/icon_chat.svg) no-repeat center/contain;
          width: 21px;
          height: 25px;
          margin-right: 12px;
          @include bpTb {
            width: 18px;
            height: 20px;
            margin-right: 6px;
          }
        }
      }
      .text {
        @include f-size(12);
        line-height: 2;
        letter-spacing: 0.1em;
        @include bpTb {
          text-align: left;
          @include f-size(15);
          letter-spacing: 0;
        }
      }
      .thumb {
        display: block;
        margin-top: 10px;
      }
    }
  }
}