.m-btn {
  &-black {
    a {
      display: inline-block;
      background-color: #000;
      color: #fff;
      @include f-size(12);
      letter-spacing: 0.1em;
      width: 305px;
      padding: 20px 0;
      text-align: center;
      position: relative;
      @include bpTb {
        width: 100%;
      }
      &:after {
        content: "";
        display: inline-block;
        background: url(../img/SVG/arrow_thin_white.svg) no-repeat center/contain;
        width: 28px;
        height: 6px;
        vertical-align: 3px;
        position: relative;
        margin-left: 10px;
      }
    }
    &.-right {
      a {
        &:after {
          position: absolute;
          right: 24px;
          top: 50%;
          margin-top: -6px;
        }
      }
    }
    &.-min {
      a {
        width: 176px;
        @include bpTb {
          width: 100%;
        }
      }
    }
    &.-full {
      a {
        width: 100%;
        &:after {
          position: absolute;
          right: 24px;
          top: 50%;
          margin-top: -6px;
        }
      }
    }
  }
}