@charset "UTF-8";
@import "./mixin";
@import "./setting";

/*******************************
Style 共通設定
*******************************/
// モノクロ化
img {
  &.gray {
    filter: grayscale(100%);
    &:hover {
      filter: grayscale(0);
    }
  }
}
@include bpTb-o {
  .pc-non {
    display: none !important;
  }
}
@include bpTb {
  .sp-non {
    display: none !important;
  }
}
.mt-0 {
  margin-top: 0px !important;
}


/*******************************
Style header
*******************************/
// サイトヘッダー
@import "../../../parts/site-header/header";
// ハンバーガーボタン
@import "../../../parts/hamburger-menu/menu";
// メニューコンテンツ
@import "../../../parts/menu-content/index";

/*******************************
Style mainパーツ
*******************************/
.main-visual {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 0;

  // 背景スライド
  @import "../../../parts/main-visual-slide/slide";
  @include bpTb {
    display: none;
  }
}
.content {
  overflow: hidden;
  .l-main {
    position: relative;
    z-index: 10;
    @include bpTb {
      margin-top: 48px;
    }
  }
}
// パンくずリスト
@import "../../../parts/breadcrumbs/breadcrumbs";

/*******************************
Style footerパーツ
*******************************/
// フッターお問い合わせ
@import "../../../parts/footer-info/footer-info";

// サイトフッター
@import "../../../parts/site-footer/footer";