.m-h2 {
  &-style01 {
    font-family: $mincho;
    font-size: 28px;
    padding: 0px 70px 8px;
    display: inline-block;
    position: relative;
    margin-bottom: 20px;
    border-bottom: 1px solid #000;
    @include bpTb {
      @include f-size(20);
    }
    a {
      position: relative;
      display: inline-block;
    }
    &__arrow {
      display: inline-block;
      width: 28px;
      height: 1px;
      background-color: #000;
      vertical-align: middle;
      position: absolute;
      top: 50%;
      right: -40px;
      margin-left: 10px;
      &:after {
        content: "";
        width: 9px;
        height: 1px;
        background-color: #000;
        transform: rotate(45deg);
        transform-origin: right;
        display: inline-block;
        position: absolute;
        right: 0px;
        bottom: 0px;
      }
    }
  }
}
