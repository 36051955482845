@charset "UTF-8";
@import "../../public_html/assets_2012/_scss/mixin";
@import "../../public_html/assets_2012/_scss/setting";

body.fixed{
  height: 100vh;
  overflow: hidden;
}


.menu-content {
  width: 100%;
  height: 110vh;
  position: fixed;
  z-index: 100;
  color: white;

  &.open {
    left: 0;
    transition: 0.3s ease-in-out all;
    animation: slideIn .2s ease-in-out;
    .nav-fix{
      width: 100%;
      max-width: 880px;
      margin: 0 auto;
    }

  }
  &.close {
    left: 100%;
    animation: slideOut .2s ease-in-out;
  }
}
@keyframes slideIn {
  0% {
    left: 100%
  }
  100% {
    left: 0
  }
}
@keyframes slideOut {
  0% {
    left: 0
  }
  100% {
    left: 100%
  }
}

.menu-content {
  background-color: white;
/*  padding: 48px 20%;*/

  .nav {
    padding-top: 85px;
    @include f-size(16);
  }
  .nav, .nav-fix {
    ul {
      display: flex;
      flex-direction: column;
      li {
        margin: 0 20px;
        position: relative;
        a{
          display: block;
          padding: 10px 0;
          border-bottom: solid 1px #333;
          position: relative;
          &::after {
            display: block;
            content: '';
            width: 10px;
            height: 10px;
            border: 0;
            border-top: solid 2px #1a1a1a;
            border-right: solid 2px #1a1a1a;
            position: absolute;
            top: 48%;
            right: 8%;
            margin-top: -4px;
            transform: rotate(45deg);
            }
      
        }
      }
    }
  }
}