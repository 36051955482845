.slick-prev:before,
.slick-next:before {
  display: none;
}
.slick-next, .slick-prev {
  display: none;
}
.slick-slide {
  width: 100%;
  height: 100%;
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;'; /*IE対策*/
  opacity: .6;
}

.slider {
  width: 100%;
  height: 100%;
  .slick-list {
    width: 100%;
    height: 100%;
    .slick-track {
      width: 100%;
      height: 100%;
    }
  }
}