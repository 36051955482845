@charset "UTF-8";
/*******************************
Default Mixin
*******************************/

/*----------------------
フォントサイズ「rem」の使用
----------------------*/
@mixin f-size($size: $baseFont) {
  font-size: $size + px;
  font-size: ($size / 10) + rem;
}

/*******************************
Customized Mixin
*******************************/
@mixin wrapper($width: $bpPcs) {
  max-width: $width;
  margin-left: auto;
  margin-right: auto;
  @include breakpoint($baseWidth) {
    max-width: $baseWidthSp;
  }
}

@mixin breakpoint($width: $bpSp) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin bpTb {
  @media screen and (max-width: $bpTb) {
    @content;
  }
}

@mixin bpTb-o {
  @media screen and (min-width: $bpTb + 1) {
    @content;
  }
}

@mixin bpSp {
  @media screen and (max-width: $bpSp) {
    @content;
  }
}

@mixin bpSps {
  @media screen and (max-width: $bpSps) {
    @content;
  }
}

@function svw( $size: 16 ) {
  $svw: $size * (100 / 750) + vw;
  @return $svw;
}

@mixin retina {
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    @content;
  }
}

@mixin iconFont() {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  line-height: 1;
}

// テキストへのドロップシャドウ付与
@mixin text_with_shadow( $font: $Gothic, $color: #fff, $shadow_color: rgba(0, 0, 0, 0.75) ) {
  font-family: $font;
  color: $color;
  text-shadow: 0px 0px 11.2px $shadow_color;
}

@mixin baseFrameStr($size) {
  text-align: center;
  padding: 0 5px;
  display: inline-block;
  @include bpTb {
    @include f-size($size);
  }
}

// オレンジの枠付き文字
@mixin orangeFrameStr($size: $baseFont) {
  color: $tour_text_orange;
  border: 1px solid $tour_text_orange;
  @include baseFrameStr($size);
}

// 緑のの枠付き文字
@mixin greenFrameStr($size: $baseFont) {
  color: $event_type_text_green;
  border: 1px solid $event_type_text_green;
  @include baseFrameStr($size);
}

//セクション見出し
@mixin sectionTitle($size: $baseFont) {
  padding-top: 50px;
  min-width: 200px;
  width: 20%;
  margin: 0 auto;
  text-align: center;
  border-bottom: solid 1px black;
  padding-bottom: 5px;
  @include f-size($size);
}

// ボタン用の矢印
@mixin btnBase($color, $size, $bwidth) {
  color: $color;
  content: '';
  display: inline-block;
  width: $size;
  height: $size;
  position: absolute;
  border-left: 0;
  border-right: solid $bwidth;
  border-top: solid $bwidth;
  transform-origin: center center;
  top: calc(50% - 7px);
}
@mixin btnArrow-L($color: #000, $size: 7px, $bwidth: 2px) {
  @include btnBase($color, $size, $bwidth);
  left: 0;
  transform: rotate(-135deg) translate(-50%,-50%);
}

@mixin btnArrow-R($color: #000, $size: 7px, $bwidth: 2px) {
  @include btnBase($color, $size, $bwidth);
  right: 0;
  transform: rotate(45deg) translate(50%,50%);
}

@mixin btnArrow-UP($color: #000, $size: 7px, $bwidth: 2px) {
  @include btnBase($color, $size, $bwidth);
  right: 0;
  transform: rotate(-45deg) translate(-50%,50%);
}

@mixin btnArrow-DW($color: #000, $size: 7px, $bwidth: 2px) {
  @include btnBase($color, $size, $bwidth);
  right: 0;
  transform: rotate(135deg) translate(50%,-50%);
}

// 共通ボタン01
@mixin btnType01($color: #fff, $bcolor: #000) {
  max-width: 300px;
  width: 100%;
  height: 70px;
  margin: 0 auto;
  background-color: $bcolor;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    opacity: .5;
  }
  a {
    color: $color;
    position: relative;
    &:after {
      @include btnArrow-DW($color, 9px);
    }
    &:hover {
      text-decoration: none;
    }
  }
}

// 比率設定画像ブロック　以下の形式で作成
//　<div class="section-picture">
// 　 <picture>
//  　  <img src="" alt="">
//  　</picture>
//　</div>
@mixin pictureBlock($ratio: 67%) {
  position: relative;
  width: 100%;
  &:before {
    content:"";
    display: block;
    padding-top: $ratio;
  }
  picture {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      font-family: 'object-fit: contain;'; /*IE対策*/
    }
  }
}

// 回転してーに変わる＋ボタン
@mixin rotatePlusBtn($color: #000, $size: 55px ) {
  position: absolute;
  width: $size;
  height: $size;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%) rotate(0deg);
  cursor: pointer;
  z-index: 100;
  transition: 0.4s cubic-bezier(0.2, 0.6, 0.3, 1.1);
  &:after, &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $color;
  }
  &:after {
    height: 2px;
    width: 50%;
  }
  &:before {
    height: 50%;
    width: 2px;
  }
  &.clicked {
    transform: translate(-50%, -50%) rotate(360deg);
    &:before {
      width: 0;
    }
  }
}

// メニュー用黒丸アローアイコン
@mixin menuArrowBK($size: 14px, $top: 14px, $right: 25px) {
  background-image: url("/assets_2012/img/1x/arrow-icon3.png");
  @include retina {
    background-image: url("/assets_2012/img/2x/arrow-icon3@2x.png");
  }
  position: absolute;
  top: $top;
  right: $right;

  content: '';
  display: inline-block;
  width: $size;
  height: $size;
  background-size: contain;
  background-color: transparent;
}