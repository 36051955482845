
.breadcrumb {
  margin: 15px auto 65px;
  max-width: 900px;
  text-align: left;
  @include bpTb {
    margin: 15px 0 15px;
    padding: 0 3.7vw;
  }
  padding: 0;
  list-style: none;
  li {
    display: inline;
    list-style: none;
    @include f-size(13);
    //font-weight: bold;
    &:after {
      content: '>';
      padding: 0 0.2em;
    }
    &:last-child:after {
      content: '';
    }
    span {
    }
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}