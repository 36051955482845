/*******************************
Style footer
*******************************/

#detail .l-footer {
  @include bpTb {
    margin-bottom: 120px;
  }
}
#thanks .l-footer {
  @include bpTb {
    margin-bottom: 0;
  }
}
.l-footer {
  background-color: $menu_black;
  color: $menu_text_white;
  height: auto;
  a {
    color: $menu_text_white;
  }
  position: relative;
  z-index: 10;
  @include bpTb {
    margin-bottom: 45px;
  }
  .footer-content {
    @include wrapper();

    .first-block {
      padding-top: 30px;
      min-width: 550px;
      width: 60%;
      margin: 0 auto;
      @include bpTb {
        min-width: 0;
        width: 80%;
        text-align: center;
      }
      .foot-logo {
        margin: 0 20px 0 85px;
        @include bpTb {
          margin: 0;
          img {
            width: 100%;
          }
        }
      }
      .fb-icon, .yt-icon {
        margin: 5px;
        @include bpTb {
          position: relative;
          top: 25px;
        }
      }
    }
    .second-block {
      @include bpTb {
        display: none;
      }
    }
    .third-block {
      @include f-size(13);
      font-size: 0.8rem;
      width: 100%;
      margin: 0 auto;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-top: 55px;
      padding-bottom: 45px;

      @include bpTb {
        width: 100%;
        padding-top: 55px;
        padding-bottom: 0;
        flex-direction: column;
      }
      .left {
        width: 25%;
        padding-top: 10px;
        @include bpTb {
          width: 100%;
          text-align: center;
        }
        &-inner {
         /* min-width: 225px;*/
          width: 90%;
         /* margin: 0 auto;*/
         @include bpTb {
           width: 100%;
           padding-top: 0;
         }

          .lead-text {
            @include f-size(12);
          }
          .tel-text {
            @include f-size(20);
            &:before {
              content: url("../img/1x/tel-icon.png");
              margin-right: 5px;
              @include retina {
                content: url("../img/1x/tel-icon.png");
              }

            }
          }
          .time-text {
            @include f-size(12);
          }

        }
      }
      .right {
        width: 35%;
        position: relative;
        @include bpTb {
          width: 100%;
          margin-top: 20px;
        }
        &:before {
          content: "";
          display: block;
          width: 1px;
          height: 100%;
          background-color: $menu_text_white;
          position: absolute;
          top: 8px;
          left: -20px;
          @include bpTb {
            width: 100%;
            height: 1px;
            top: -3px;
            left: 0;
          }
        }

        ul {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin-top: 10px;
          @include bpTb {
            justify-content: center;
          }
          li {
            font-size: 1.2rem;
            margin-right: 20px;
            line-height: 2.4rem;
            @include bpTb {
              line-height: 3.4rem;
            }
          }
        }
        &:last-of-type {
          width: 40%;
          @include bpTb {
            width: 100%;
          }
        }
      }
    }
    .foot-copy {
      @include f-size(18);
      min-width: 550px;
      width: 90%;
      margin: 0 auto;
      @include bpTb {
        width: 100%;
        min-width: 0;
        text-align: center;
        @include f-size(9);
      }
    }

  }
}

.nav {
  padding-top: 25px;
  margin: 0 auto;
  @include f-size(12);
  max-width: 880px;
  width: 100%;
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    li {
      margin: 0 20px;
      position: relative;
      &:not(:first-child):before {
        content: "|";
        position: absolute;
        top: -1px;
        left: -20px;
      }
    }
  }
}