@charset "UTF-8";
@import "../../public_html/assets_2012/_scss/mixin";
@import "../../public_html/assets_2012/_scss/setting";

.sp-tel-btn {
  display: none;
  @include bpTb {
    display: block;
    margin-right: -20px;
  }
}
.lines-button, .lines-button2 {

  display: none;
  @include breakpoint($bpPcsOrg) {
    display: block;
  }

  margin-bottom: 0;
  touch-action: manipulation;
  cursor: pointer;
  outline: 0;
  outline-offset: 0;
  
  white-space: nowrap;
  user-select: none;
  z-index: 20;
  position: relative;
  top: 4px;
  right: 0;
}

.lines-button {
  position: relative;
  z-index: 30;
  color: $menu_text_white;
  margin: 0;
  width: 30px;
  height: 30px;
  @include breakpoint($bpPcs) {
    display: block;
  }
  &.open {
    span {
      background: 0 0;
      &::before {
        top: 0;
        transform: rotate(160deg);
        background: $menu_text_white;
      }
      &::after {
        bottom: 0;
        transform: rotate(200deg);
        background: $menu_text_white;
      }
    }
  }

  span {
    position: absolute;
    width: 30px;
    height: 1px;
    background: $menu_text_white;
    top: 40%;
    left: 0;
    transition: background .3s;
    &::before, &::after {
      content: '';
      position: absolute;
      left: 0;
      background: $menu_text_white;
      width: 100%;
      height: 100%;
      transform: translateZ(0);
      backface-visibility: hidden;
      transition: transform .3s,background 0s,-webkit-transform .3s;
    }
    &::before {
      top: -9px
    }
    &::after {
      bottom: -9px;
    }
    @include bpTb {
      width: 30px;
      &::before {
        top: -8px
      }
      &::after {
        bottom: -8px;
      }

    }
  }

}